import React from 'react'
import Layout from '../../components/Layout'

export default class UnderConstructionIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="margin-top-0">
          <h1
            className="has-text-weight-bold is-size-2"
            style={{
              boxShadow: '0.5rem 0 0 #f40, -0.5rem 0 0 #f40',
              backgroundColor: '#f40',
              color: 'white',
            }}>
            Welcome to Kev4Call
          </h1>
        </div>
        <section className="section">
            <p>This site is currently under construction. Please check back later.</p>
        </section>
      </Layout>
    )
  }
}
